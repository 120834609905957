import { useEffect } from 'react';
import styled from 'styled-components';
import useWindowDimensions from './hooks/dimentions';
import Card from './components/card';
import Logo from './components/logo';
import SearchBar from './components/searchBar';
import Table from './components/table';
import Loading from './components/Loading';
import axios from 'axios';
import { useState } from 'react';
import FinalResults from './FinalResults-26.01.json';

function App() {
	const [results, setResults] = useState([]);
	const [filteredResults, setFilteredResults] = useState([]);
	const [loading, setLoading] = useState(true);
	const { isMobile } = useWindowDimensions();

	useEffect(() => {
		// const startDate = new Date('2023-01-25T08:00:00.000Z');
		// const endDate = new Date('2023-01-27T20:00:00.000Z');
		// const route = 'https://api.singit.io/lesson/leaderboard';
		// Promise.all([
		// 	axios.post(route, {
		// 		startDate,
		// 		endDate,
		// 		schoolsIdmIDs: ['612135', '412189', '573097', '510578', '442483', '416289', '415430', '213991', '412783', '611715', '411918', '415984'],
		// 		lessonsIDs: [
		// 			'63b552849f732b000f121244',
		// 			'63b56703b49336000ea9d96b',
		// 			'63b56ce29d5e36000f5b6507',
		// 			'63b577f89d5e36000f5b6a35',
		// 			'63beaf07213ccc000ebcf8e4',
		// 		],
		// 	}),
		// 	axios.post(route, {
		// 		startDate,
		// 		endDate,
		// 		schoolsIdmIDs: ['180018', '248625', '770057'],
		// 		lessonsIDs: [
		// 			'63beb817213ccc000ebd1741',
		// 			'63bebb53213ccc000ebd239a',
		// 			'63bebedb213ccc000ebd2809',
		// 			'63bec28b213ccc000ebd2ccf',
		// 			'63cd002ec12eeb000e4335aa',
		// 		],
		// 	}),
		// 	axios.post(route, {
		// 		startDate,
		// 		endDate,
		// 		schoolsIdmIDs: ['441030', '470641', '260489', '620187'],
		// 		lessonsIDs: [
		// 			'63bec6e7213ccc000ebd3219',
		// 			'63beca96213ccc000ebd3c78',
		// 			'63becea9213ccc000ebd464b',
		// 			'63bed196213ccc000ebd4e3e',
		// 			'63cfb3dfb569fa000eef9f6b',
		// 		],
		// 	}),
		// ])
		// 	.then((result) => {
		// 		console.log(result);

		// 		setSchools({
		// 			elementarySchools: result[0].data,
		// 			juniorHighs: result[1].data,
		// 			// HighSchools: result[2].data,
		// 		});
		// 		setLoaded(true);
		// 	})
		// 	.catch((err) => console.log(err));

		setResults(FinalResults);
		setFilteredResults(FinalResults);
		setLoading(false);
	}, []);

	const onSearch = (value) => {
		if (!value?.length) setFilteredResults(results);
		else setFilteredResults(results.filter((i) => i.parents[0]?.name.includes(value)));
	};

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<>
					<div className='App' dir='rtl'>
						{/* {[
							{ type: 'elementarySchools', header: 'תחרות בתי הספר היסודיים של' },
							{ type: 'juniorHighs', header: 'תחרות חטיבות הביניים של' },
							{ type: 'HighSchools', header: 'תחרות התיכונים של' },
						].map((item, index) => { })} */}
						<Wrapper>
							<header>
								<h1 style={{ margin: '30px 0 -10px' }}>תחרות בתי הספר של</h1>
								<Logo />
							</header>

							<CardsWrapper>
								{results?.slice(0, 3).map((schoolObj, index) => (
									<Card index={index + 1} school={schoolObj} key={index} />
								))}
							</CardsWrapper>

							<TitleRow className={isMobile ? '' : 'row'}>
								<h2>כל בתי הספר המשתמשים</h2>
								<SearchBar onSearch={onSearch} />
							</TitleRow>

							<Table schools={filteredResults} />
						</Wrapper>
					</div>
				</>
			)}
		</>
	);
}

export default App;

const Wrapper = styled.div`
	padding: 10px;
`;

const CardsWrapper = styled.div`
	margin: 10px 0 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
`;

const TitleRow = styled.div`
	margin: 40px 0 0;
`;
