import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import styled from "styled-components";

const Circle = (props) => {
  const [width, setWidth] = useState(0);
  const [color, setColor] = useState("");
  
  const calcSize = useCallback(() => {
    if (props.size === "small") return 3;
    else if (props.size === "medium") return 4;
    else if (props.size === "large") return 5;
    else if (props.size === "xl") return 8;
  }, [props.size]);

  const generateColor = useCallback(() => {
    if(props.score > 90) return "#7A55B5";
    else if(props.score > 75) return "#9B80C8";
    if(props.score > 50) return "#BDAADA";
    else return "#DED5ED";
  }, [props.score]);

  useEffect(() => {
    setWidth(calcSize());
    setColor(generateColor());
  }, [calcSize, generateColor]);

  return <Wrapper>
    <CircleWrapper width={width} color={color}>
      <div className="content">{props.score}</div>
    </CircleWrapper>
    {props.index && <p>#{props.index}</p>}
  </Wrapper>
};

export default Circle;

const Wrapper = styled.div`
  text-align: center;
  width: min-content;
  p {margin: 5px 0 0; font-weight: medium; font-size: 0.8em;}
`;

const CircleWrapper = styled.div`
  width: ${props => props.width}0px;
  height: ${props => props.width}0px;
  font-size: ${props => props.width / 3}em;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.color};
  border-radius: 50%;
  color: white;
  font-weight: bold;
  box-shadow: var(--shadow);
`;