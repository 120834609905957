import { useState } from 'react';
import styled from 'styled-components';
import useWindowDimensions from '../hooks/dimentions';
import Card from './card';
import Modal from './modal';
import TableRow from './tableRow';

const Table = (props) => {
	const [selected, setSelected] = useState(null);
	const { isMobile } = useWindowDimensions();

	return (
		<>
			<TableWrapper>
				<thead>
					<tr>
						<th>מקום</th>
						<th>בית ספר</th>
						<th>כיתה</th>
						{!isMobile && <th>תלמידים</th>}
						{!isMobile && <th>ציון ממוצע למטלה</th>}
						<th>ציון ממוצע סופי</th>
						{isMobile && <th></th>}
					</tr>
				</thead>
				<tbody>
					{props?.schools?.map((item, index) => (
						<TableRow key={index} index={index} isMobile={isMobile} item={item} onSelect={setSelected} />
					))}
				</tbody>
			</TableWrapper>
			{selected && (
				<Modal dismiss={() => setSelected(null)}>
					<Card school={selected} />
				</Modal>
			)}
		</>
	);
};

export default Table;

const TableWrapper = styled.table`
	width: 100%;
	border-collapse: collapse;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: var(--shadow);

	thead {
		color: white;
		background-image: linear-gradient(to right, #7a55b5, #9b80c8, #7a55b5, #9b80c8);
	}

	tr {
		border-bottom: 2px solid var(--dark-purple);
	}

	th {
		padding: 10px;
	}

	td {
		padding: 10px;
		text-align: center;
	}

	.center {
		margin: 0 auto;
		width: min-content;
	}

	.chevron-left {
		height: 15px;
		display: block;
	}
`;
