import styled from "styled-components";
import Icon from "../assets/logo.jpg";

const Logo = () => {
  return <Wrapper>
    <h1>Singit</h1>
    <img src={Icon} alt="logo" />
  </Wrapper>;
};

export default Logo;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img { height: 50px; margin-inline-start: 15px; }
  h1 { margin: 0; font-size: 50px; font-family: 'Pacifico', cursive; color: var(--dark-purple); }
`;