import styled from "styled-components";

const Modal = (props) => {
  return <>
    <Backdrop onClick={props.dismiss} />
    <ModalWrapper>
      {props.children}
    </ModalWrapper>
  </>
};

export default Modal;

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.6);
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0);
`;