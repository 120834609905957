const ParseClassName = (name) => {
	let regex = new RegExp(/^class [0-9]*-[0-9]*$/, 'gi');
	if (regex.test(name)) {
		try {
			let grades = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט', 'י', 'יא', 'יב'];
			let dashIndex = name.indexOf('-');
			let grade = Number.parseInt(name.substring(6, dashIndex));
			let classNum = name.substring(dashIndex + 1, name.length);
			return grades[grade] + "'-" + classNum;
		} catch (err) {
			return name;
		}
	}

	return name;
};

export default ParseClassName;
