import styled from "styled-components";
import useWindowDimensions from "../hooks/dimentions";
import Search from "../assets/search.png";
import Close from "../assets/close.png";

const SearchBar = (props) => {
  const { isMobile } = useWindowDimensions();

  return <InputWrapper isMobile={isMobile}>
    <SearchIcon src={Search} alt="search icon" />
    <Input placeholder="חיפוש בתי ספר" onChange={e => props.onSearch(e.target.value)} />
  </InputWrapper>
}

export default SearchBar;

const InputWrapper = styled.div`
  border-radius: 10px;
  box-shadow: var(--shadow);
  padding: 10px 15px;
  display: flex;
  align-items: center;
  background-color: white;
  margin-bottom: ${props => props.isMobile ? "20px" : 0};
`;

const Input = styled.input`
  border: none;
  font-size: 1em;
  background-color: transparent;
  outline: none;
  width: 100%;
`;

const SearchIcon = styled.img`
  height: 15px;
  margin-inline-end: 10px;
`;

const CloseIcon = styled.img`
  height: 10px;
  cursor: pointer;
`;