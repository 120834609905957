import Circle from './circle';
import ChevronLeft from '../assets/chevron-left.png';
import ParseClassName from '../functions/parseClassName';

const TableRow = (props) => {
	return (
		<tr>
			<td>{props.index + 1}</td>
			<td>{props.item.parents[0]?.name}</td>
			<td>{ParseClassName(props.item.name)}</td>
			{!props.isMobile && <td>{props.item.studentsCount}</td>}
			{!props.isMobile && (
				<td className='row'>
					{props.item.lessons.slice(0, 5).map((item, index) => (
						<Circle size='small' score={Math.round(item.score)} index={index + 1} key={index} />
					))}
				</td>
			)}
			<td>
				<div className='center'>
					<Circle size='medium' score={Math.round(props.item.score)} />
				</div>
			</td>
			{props.isMobile && (
				<td onClick={() => props.onSelect(props.item)}>
					<img src={ChevronLeft} alt='Chevron Left' className='chevron-left' />
				</td>
			)}
		</tr>
	);
};

export default TableRow;
