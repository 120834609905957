import styled from 'styled-components';

import Gold from '../assets/gold-medal.png';
import Silver from '../assets/silver-medal.png';
import Bronze from '../assets/bronze-medal.png';
import Circle from './circle';
import ParseClassName from '../functions/parseClassName';

const Card = (props) => {
	return (
		<Wrapper>
			{/* <div className="row"> */}
			<div className='info'>
				<h3>{props.school.parents[0]?.name}</h3>
				<p>
					כיתה {ParseClassName(props.school.name)} - {props.school.studentsCount} תלמידים
				</p>
				{/* </div> */}
				{props.index === 1 && <img src={Gold} alt='medal' />}
				{props.index === 2 && <img src={Silver} alt='medal' />}
				{props.index === 3 && <img src={Bronze} alt='medal' />}
			</div>
			<h2>ציון ממוצע למטלה</h2>
			<div className='row'>
				{props.school?.lessons?.slice(0, 5).map((item, index) => (
					<Circle size='small' score={Math.round(item.score)} index={index + 1} key={index} />
				))}
			</div>
			<h2>ציון ממוצע סופי</h2>
			<div className='floating-score'>
				<Circle score={Math.round(props.school.score)} size='xl' />
			</div>
		</Wrapper>
	);
};

export default Card;

const Wrapper = styled.div`
	background-color: white;
	text-align: start;
	padding: 25px 25px 50px;
	border-radius: 10px;
	box-shadow: var(--shadow);
	position: relative;
	margin: 30px 15px;
	min-width: 230px;
	max-width: 280px;
	flex: 1;

	.info {
		text-align: center;
		p,
		h3 {
			margin: 0;
		}
	}

	img {
		max-width: 100px;
		position: absolute;
		top: -30px;
		left: -30px;
	}

	h2 {
		text-align: center;
		margin: 25px 0 10px;
		font-size: 18px;
		font-weight: medium;
	}

	.floating-score {
		position: absolute;
		bottom: -40px;
		margin: 0 auto;
		left: 50%;
		transform: translate(-50%, 0);
	}
`;
