import PuffLoader from 'react-spinners/PuffLoader';
import styled from 'styled-components';

const Loading = (props) => {
	return (
		<Wrapper>
			<PuffLoader color='#735fff' size={100} css='margin: 0 auto;' />
		</Wrapper>
	);
};

export default Loading;

const Wrapper = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;
